import dayjs from 'dayjs'
import { SavedProductOfferingConfig } from '@/GeneratedTypes/SavedProductOfferingConfig'
import store from '@/store'
import { cloneDeep } from 'lodash'

export function setDatesForNewEstimate(hasCheer: boolean) {
  const config = cloneDeep(store.getters.productOfferings.productOfferingConfig) as SavedProductOfferingConfig
  if (!config.firstPracticeDateEstimate) {
    config.firstPracticeDateEstimate = dayjs().toDate()
  }
  if (!config.playerEstimate) {
    config.playerEstimate = 100
  }
  if (!config.sportCoachEstimate) {
    config.sportCoachEstimate = 10
  }
  if (hasCheer && !config.cheerEstimate) {
    config.cheerEstimate = 75
  }
  if (hasCheer && !config.cheerCoachEstimate) {
    config.cheerCoachEstimate = 7
  }
  store.commit.productOfferings.setProductOfferingConfig({ item: config })
}
